/* globals.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "antd/dist/reset.css";

/* // fix Tailwind CSS border styles,form Tailwind CSS's preflight */
*,
::before,
::after {
  /* box-sizing: border-box; 1 */
  border-width: 0; /* 2 */
  border-style: solid; /* 2 */
  border-color: theme("borderColor.DEFAULT", currentColor); /* 2 */
}

::before,
::after {
  --tw-content: "";
}
/* .ant-btn-primary {
  color: black!important;
}
.ant-menu-item-selected {
  color: black!important; 
} */
.awssld__content 
{
  background-color: transparent!important;
}
.awssld__controls__arrow-left {
  transition: transform 0s ease-out 0.1255s, opacity 0.2s ease-out!important;
}
.awssld__controls__arrow-right {
  transition: transform 0s ease-out 0.1255s, opacity 0.2s ease-out!important;
}
.blog-content-html img{
  width: 640px!important;
}
.blog-content-html  p:has(img),h1:has(img), h2:has(img), h3:has(img), h4:has(img), h5:has(img), h6:has(img){
  /* Your styles for <p> elements with <img> child elements */
  /* For example, you could apply flex styles here */
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .ql-editor {
  overflow-y: scroll;
  resize: vertical;
} */
.ql-container.ql-snow 
{
  height: 300px;
}
.transition-container {
  transition-property: opacity, visibility;
  transition-duration: 0.3s;
  opacity: 0;
  visibility: hidden;
}
.transition-container.active {
  opacity: 1;
  visibility: visible;
}
/* table, th, td {
  border: 1px solid black;
} */

.ck .ck-content
{
  height: 250px;
  overflow-y: scroll;
  resize: vertical;

}