.slider-tool .swiper-button-prev {
  left: 220px !important;
  background-color: #1212120d !important;
}
.slider-tool .swiper-button-next {
  right: 225px !important;
  background-color: #1212120d!important;
}
.swiper-button-prev {
    border-radius: 50% !important;
    width: 32px !important;
    height: 32px !important;
    color: inherit !important;
  }
  .swiper-button-prev::after {
    font-size: 15px !important;
  }
  .swiper-button-next {
 
    border-radius: 50% !important;
    width: 32px !important;
    height: 32px !important;
    color: inherit !important;
  }
  .swiper-button-next::after {
    font-size: 15px !important;
  }
  
  .swiper-pagination {
    position: relative !important;
    margin-top: 10px !important;
  }
  .swiper-pagination-bullet {
    opacity: 1!important;
    border: 1px solid black!important;
    background-color: black!important;
  }
  .swiper-pagination-bullet-active {
    background-color: black !important;
    width: 20px !important;
    border-radius: 8px !important;
  }
  .slider-slide-card {
    width: 520px;
    height: auto;
    border-radius: 20px !important;
    border: 1px solid #1212120d !important;
  }
  @media only screen and (max-width: 1024px) {
    .swiper-button-prev {
      left: 90px !important;
    }
    .swiper-button-next {
      right: 90px !important;
    }
  }
  @media only screen and (max-width: 768px) {
    .swiper-button-prev {
      left: 60px !important;
    }
    .swiper-button-next {
      right: 60px !important;
    }
  }
  @media only screen and (max-width: 425px) {
    .swiper-button-prev {
      left: 10px !important;
    }
    .swiper-button-next {
      right: 10px !important;
    }
  } 